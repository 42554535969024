import { getConfig } from './config';

if (window.apw.APW_ROOT_PAGE_TITLE) document.title = window.apw.APW_ROOT_PAGE_TITLE;

const processName = keyName =>
  keyName
    .split('_')
    .map((value, index) =>
      index
        ? value
            .split('')
            .map((letter, lIdx) => (lIdx ? letter.toLowerCase() : letter.toUpperCase()))
            .join('')
        : value.toLowerCase(),
    )
    .join('');

getConfig()
  .then(apwConfig => {
    const { apw } = window;
    const regIsSubConfig = new RegExp(/APW_.*_OPTIONS/);
    const regIsRootConfig = new RegExp(/^APW_((?!_OPTIONS).)*$/);
    for (const key in apwConfig) {
      if (regIsRootConfig.test(key)) {
        apw[key] = apwConfig[key];
      } else if (regIsSubConfig.test(key)) {
        const config = apwConfig[key];
        const keyName = processName(key);
        window[keyName] = Object.freeze(config);
      }
    }
    window.apw = Object.freeze(apw);
  })
  .finally(() => {
    import('./bootstrap');
  });
